<template>
  <section class="container my-24 my-lg-32">
    <div class="row">
      <header class="col">
        <b>Страница 1 из 15</b>
      </header>
    </div>

    <section class="row mt-8">
      <div class="col">
        <v-card class="py-32 px-16 p-xl-32">
          <linear-choice360
            v-for="i of 10"
            v-model="answer"
            :key="i"
            :variants="variants"
            :noVariant="noVariant"
            class="mb-24 mb-xl-16"
          >
            <template #textLeft>
              {{ leftText }}
            </template>

            <template #textRight>
              {{ rightText }}
            </template>
          </linear-choice360>

          <div class="row mt-40">
            <div class="col">
              <v-button>Ответить</v-button>
            </div>
          </div>
        </v-card>
      </div>
    </section>
  </section>
</template>

<script>
import { VButton, VCard } from '@components/base'
import LinearChoice360 from '@components/features/LinearСhoice/LinearChoice360'

export default {
  name: 'S360ChoiceOfApproval',

  components: {
    LinearChoice360,
    VButton,
    VCard
  },

  data () {
    return {
      answer: '',
      leftText: 'Не берет на себя ответственность за решение задач. Ставит для себя заведомо легкие для достижения цели',
      rightText: 'С готовностью берет на себя ответственность за решение сложных задач',
      variants: [
        { id: 1, text: 'Точно А' },
        { id: 2, text: 'Скорее А' },
        { id: 3, text: 'Между А и Б' },
        { id: 4, text: 'Скорее Б' },
        { id: 5, text: 'Точно Б' }
      ],
      noVariant: { id: 6, text: 'Не знаю' }
    }
  }
}
</script>
